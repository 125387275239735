<template>
  <v-slide-group
    v-model="value"
    class="slide-addons"
    :show-arrows="showArrows"
    :class="!showArrows ? 'slide-addons_hidden' : '' "
    center-active
    @click:next="nextData"
  >
    <v-slide-item
      class="slide-addons__item"
      v-for="item in items"
      :key="item.id"
    >
      <v-card
        width="209"
        max-height="281"
        class="slide-addons__card"
        style="margin-right: 20px"
      >
        <div class="slide-addons__lienzo">
          <div class="slide-addons__options">
            <cp-dropdown-icon
              margTop="12px"
              margLeft="14px"
              :items="options"
              @selected-action="actionToDo($event, item, formatDataLeadsHome)"
            />
          </div>
          <img class="slide-addons__image" :src="item.finalImage" />
        </div>
        <v-sheet class="slide-addons__footer" :min-height="49">
          <div class="card-header-design align-vertical-design">
            {{ item.name }}
          </div>
          <div class="card-date-design align-vertical-design">
            {{ getStringDate(item.createdAt) }}
          </div>
        </v-sheet>
        <json-excel-home
          :data="formatData"
          :id="'to_excel-' + item.id"
          :name="`Leads-${item.name}.xls`"
          style="display: none"
        >
          Download Data
        </json-excel-home>
      </v-card>
    </v-slide-item>
    <element v-for="(item, index) in voids" :key="index.id">
      <div class="void_item"></div>
    </element>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      @input="closeIconX"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
    />
  </v-slide-group>
  
</template>

<script>
import CpDropdownIcon from "@/components/dropdown/DropDownIcon";
import { transformStringDate, IscModalAlert, } from "@design/styleguide";
import JsonExcelHome from "vue-json-excel";
import { addons } from "@/mixins/addons";
import { cardsFolder } from "@/mixins/cards";

export default {
  name: "CardAddonsHome",
  mixins: [addons, cardsFolder],
  components: {
    CpDropdownIcon,
    JsonExcelHome,
    IscModalAlert
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    showArrows: {
      type: [Boolean, String],
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      excelName: "data-leads.xls",
      options: [
        {
          title: "Edit",
          icon: "far fa-edit",
        },
        {
          title: "Clone",
          icon: "far fa-clone",
        },
        {
          title: "Url",
          icon: "fa fa-eye",
        },
        {
          title: "Download",
          icon: "fa fa-download",
        },
        {
          title: "Delete",
          icon: "far fa-trash-alt",
        },
      ],
    };
  },
  mounted() {
    this.cardsByDefault(this.items);
  },
  methods: {
    nextData() {
      this.$emit("trigger-charge", this.value);
    },
    getStringDate(date) {
      return transformStringDate(date, "MM-DD-YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-addons {
  min-height: 304px;
  &__card {
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1) !important;
    border-radius: 6px;

    .h5-title-addon {
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
    }
    .card-title-design {
      font-family: Montserrat;
      font-size: 12px !important;
      font-weight: 900 !important;
      line-height: 20px !important;
      color: #ea9a41;
    }
    .card-title-design > span {
      font-weight: 300;
    }
    .card-subtitle-design {
      font-family: Oxygen;
      font-size: 8px;
      font-weight: 700;
      line-height: 20px;
      color: black;
    }
    .card-description-design {
      font-family: Oxygen;
      font-size: 6px;
      font-weight: 400;
      line-height: 20px;
    }
    .card-header-design {
      font-family: Oxygen;
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
    }
    .card-date-design {
      font-family: Oxygen;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }
    .align-vertical-design {
      height: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }
    .v-card__text {
      width: 85% !important;
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &__item:hover &__options {
    display: block;
  }
  &__image {
    width: 209px;
    height: 209px;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
    background-color: #f3f4f7;
  }
  &__lienzo {
    position: relative;
    max-height: 209px;
  }
  &__question {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 20px;
    border: 1px solid #c5d0e4;
    box-sizing: border-box;
    margin-right: 10px;
  }
  &__footer {
    padding-top: 21px;
    padding-left: 18px;
  }
  &_hidden {
    ::v-deep.v-slide-group.v-item-group>.v-slide-group__next .v-icon.v-icon{
      display: none;
    }
    ::v-deep.v-slide-group.v-item-group>.v-slide-group__prev .v-icon.v-icon{
      display: none;
    }
    ::v-deep.v-slide-group.v-item-group>.v-slide-group__prev {
      cursor: unset;
    }
    ::v-deep.v-slide-group.v-item-group>.v-slide-group__next {
      cursor: unset;
    }
    
  }
  .void_item {
    position: relative;
    width: 209px;
    height: 281px;
    background: #ffffff;
    float: left;
    text-align: center;
    margin: 0px 20px 0px 0px;
    opacity: 0.5;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
  }
}
@media screen and (max-width: 1024px) {
  .new_item_crsl,
  .void_item {
    width: 179px !important;
    height: 251px !important;
  }
}
</style>
