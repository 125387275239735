<template>
  <v-app id="main">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<style scoped>
#main {
  margin-top: 60px;
}
</style>

<script>
export default {
  metaInfo() {
    return {
      title: "Isocialcube",
      meta: [
        {
          name: "description",
          content: "Isocialcube",
        },
        {
          property: "og:title",
          content: "Isocialcube",
        },
        { property: "og:site_name", content: "Isocialcube" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>