<template>
  <div>
    <v-slide-group
      v-model="value"
      class="slide-addons"
      :show-arrows="showArrows"
      :class="!showArrows ? 'slide-addons_hidden' : ''"
    >
      <v-slide-item
        class="slide-addons__item"
        v-for="item in items"
        :key="'cardpost' + item.id"
      >
        <v-card
          width="209"
          max-height="281"
          class="slide-addons__card"
          style="margin-right: 20px"
        >
          <div class="slide-addons__lienzo">
            <div class="slide-addons__options">
              <drop-down-icon
                margTop="12px"
                margLeft="14px"
                :items="options"
                @selected-action="actionToDo($event, item)"
              />
            </div>
            <img class="slide-addons__image" :src="item.image" />
          </div>
          <v-sheet class="slide-addons__footer" :min-height="49">
            <div class="card-header-design align-vertical-design">
              {{ item.name }}
            </div>
            <div class="card-date-design align-vertical-design">
              {{ item.createdAt }}
            </div>
          </v-sheet>
        </v-card>
      </v-slide-item>
      <element v-for="(item, index) in voids" :key="index.id">
        <div class="void_item"></div>
      </element>
    </v-slide-group>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
      @input="closeIconX"
    />
  </div>
</template>

<script>
import { IscModalAlert } from "@design/styleguide";
import DropDownIcon from "../../dropdown/DropDownIcon";
import { cardsFolder } from "@/mixins/cards";
import { PostMixin } from "@/mixins/postMixin";
export default {
  name: "PostsCards",
  mixins: [cardsFolder, PostMixin],
  components: {
    DropDownIcon,
    IscModalAlert,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    showArrows: {
      type: [Boolean, String],
      default: () => {
        return false;
      },
    },
  },
  methods: {},
  mounted() {
    this.cardsByDefault(this.items);
  },
};
</script>

<style lang="scss" scoped>
.slide-addons {
  min-height: 304px;
  &__card {
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1) !important;
    border-radius: 6px;

    .h5-title-addon {
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
    }
    .card-header-design {
      font-family: Oxygen;
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 16px;
    }
    .card-date-design {
      font-family: Oxygen;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }
    .align-vertical-design {
      height: 50%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 4px;
    }
    .v-card__text {
      width: 85% !important;
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &__item:hover &__options {
    display: block;
  }
  &__image {
    width: 209px;
    height: 209px;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
    background-color: #f3f4f7;
  }
  &__lienzo {
    position: relative;
    max-height: 209px;
  }

  &__footer {
    padding-top: 21px;
    padding-left: 18px;
  }
  &_hidden {
    ::v-deep.v-slide-group.v-item-group > .v-slide-group__next .v-icon.v-icon {
      display: none;
    }
    ::v-deep.v-slide-group.v-item-group > .v-slide-group__prev .v-icon.v-icon {
      display: none;
    }
    ::v-deep.v-slide-group.v-item-group > .v-slide-group__prev {
      cursor: unset;
    }
    ::v-deep.v-slide-group.v-item-group > .v-slide-group__next {
      cursor: unset;
    }
    /* ::v-deep.v-slide-group__next{
      cursor: none
    } */
  }
  .void_item {
    position: relative;
    width: 209px;
    height: 281px;
    background: #ffffff;
    float: left;
    text-align: center;
    margin: 0px 20px 0px 0px;
    opacity: 0.5;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
  }
}
</style>
